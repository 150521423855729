<template>
  <div>
    <!-- 検索条件入力欄 START -->
    <div class="l-full-white l-area">
      <!-- 詳細・簡易タプ表示枠 START -->
      <div class="l-box l-flex">
        <h3 class="c-lead-s-black icon">検索</h3>
        <div class="c-tabs l-area-l">
          <div
            class="c-tabs__each"
            :class="{ active: !isSearchDetail }"
            @click="
              isSearchDetail = false;
              initSearch(true);
            "
          >
            簡易
          </div>
          <div
            class="c-tabs__each"
            :class="{ active: isSearchDetail }"
            @click="
              isSearchDetail = true;
              initSearch(false);
            "
          >
            詳細
          </div>
        </div>
      </div>
      <!-- 詳細・簡易タプ表示枠 END -->

      <div class="c-inputWrap">
        <div class="c-inputWrap__items__cols flex-end">
          <!-- 件名入力フィールド START -->
          <div>
            <span class="text-label">件名</span>
            <InputText
              class="xlg"
              :value.sync="searchParam.subject"
              inputType="text"
              placeholder="件名を入力"
              :validation="validateMaxLen"
              :validateArg="100"
            >
            </InputText>
          </div>
          <!-- 件名入力フィールド END -->

          <!-- 担当者入力フィールド START -->
          <div>
            <span class="text-label">担当者</span>
            <Selectbox
              class="mid"
              :value.sync="searchParam.memberId"
              :validation="validateJustBlank"
            >
              <option value=""></option>
              <option
                v-for="(opt, index) in memberList"
                :key="`gc-opt-${index}`"
                :value="opt.id"
              >
                {{ opt.name }}
              </option>
            </Selectbox>
          </div>
          <!-- 担当者入力フィールド END -->
        </div>
      </div>

      <div class="c-inputWrap">
        <div class="c-inputWrap__items__cols center search-wrap">
          <!-- 運搬事業者入力フィールド START -->
          <div
            v-for="(list, index) in searchParam.collectCompanyId"
            :key="`t-slect-${index}`"
            class="c-input"
          >
            <span class="text-label">運搬事業者</span>
            <div class="c-inputWrap__items__cols center search-wrap">
                <v-autocomplete ref="collect_autocomplete" @change="s => {onChange(s, 'collect_autocomplete')}" placeholder="" class="c-input" :items="selectGeneratorCollectList" :min-len="0" :wait="300" @update-items='(text) => updateGeneratorCollectList(text)' v-model="selectedGeneratorCollect[index]" :component-item='selectTemplate' :auto-select-one-item="autoSelect" :get-label='getLabel'>
                </v-autocomplete>
              <span
                v-if="searchParam.collectCompanyId.length > 1 && index > 0"
                class="c-btn-icon delete secondary small l-item-l"
                @click="deleteCollectList(index)"/>
            </div>
          </div>
          <div
            v-if="searchParam.collectCompanyId.length < 5"
            class="c-btn-icon add secondary sm"
            @click="addCollect"
          ></div>
          <!-- 運搬事業者入力フィールド END -->
        </div>
      </div>

      <div class="c-inputWrap">
        <div class="c-inputWrap__items__cols center search-wrap">
          <!-- 処分事業者入力フィールド START -->
          <div
            v-for="(list, index) in searchParam.disposalCompanyId"
            :key="`d-slct-${index}`"
            class="c-input"
          >
            <span class="text-label">処分事業者</span>
            <div class="c-inputWrap__items__cols center search-wrap">
                <v-autocomplete ref="disposal_autocomplete" @change="s => {onChange(s, 'disposal_autocomplete')}" placeholder="" class="c-input" :items="selectGeneratorDisposalList" :min-len="0" :wait="300" @update-items='updateGeneratorDisposalList' v-model="selectedGeneratorDisposal[index]" :component-item='selectTemplate' :auto-select-one-item="autoSelect" :get-label='getLabel'>
                </v-autocomplete>
              <span
                v-if="searchParam.disposalCompanyId.length > 1 && index > 0"
                class="c-btn-icon delete secondary small l-item-l"
                @click="deleteDisposalList(index)"/>
            </div>
          </div>
          <div
            v-if="searchParam.disposalCompanyId.length < 5"
            class="c-btn-icon add secondary sm"
            @click="addDisposal"
          ></div>
          <!-- 処分事業者入力フィールド END -->
        </div>
      </div>

      <!-- 詳細のときだけ表示 START -->
      <template v-if="isSearchDetail">
        <div class="c-inputWrap">
          <div class="c-inputWrap__items__cols search-wrap">
            <!-- 登録日入力フィールド START -->
            <div>
              <span class="text-label">登録日</span>
              <div class="c-inputWrap__items__cols search-wrap">
                <InputDate
                  :value.sync="searchParam.createDateFrom"
                  placeholder="0000/00/00"
                />
                <span>〜</span>
                <InputDate
                  :value.sync="searchParam.createDateTo"
                  placeholder="0000/00/00"
                />
              </div>
            </div>
            <!-- 次回更新日入力フィールド START -->
            <div>
              <span class="text-label">次回更新日</span>
              <div class="c-inputWrap__items__cols search-wrap">
                <InputDate
                  :value.sync="searchParam.nextUpdateDateFrom"
                  placeholder="0000/00/00"
                />
                <span>〜</span>
                <InputDate
                  :value.sync="searchParam.nextUpdateDateTo"
                  placeholder="0000/00/00"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="c-inputWrap">
          <div class="c-inputWrap__items__cols search-wrap">
            <!-- 添付ファイル入力フィールド START -->
            <div>
              <span class="text-label">添付ファイル</span>
              <div class="l-flex cols">
                <div class="c-input">
                  <div class="c-checkbox">
                    <input
                      id="is-auto-true"
                      type="checkbox"
                      v-model="searchParam.isSetFile"
                      :value="true"
                    />
                    <label class="c-checkbox__label" for="is-auto-true">
                      <span class="c-checkbox__box"></span>
                      有のみ表示
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!-- 詳細のときだけ表示 END -->
    </div>
    <!-- 検索条件入力欄 END -->

    <!-- ボタン表示枠 START -->
    <div class="l-container">
      <div class="l-btns l-block-lg">
        <div class="c-btn secondary small" @click="resetSearchHandler">
          条件をリセット
        </div>
        <div class="c-btn primary small" @click="searchHandler">検索</div>
      </div>
    </div>
    <!-- ボタン表示枠 END -->
  </div>
</template>

<script>
import InputText from "@/components/parts/input/InputText";
import InputDate from "@/components/parts/input/InputDate";
import Selectbox from "@/components/parts/input/Selectbox";
import formValidation from "../mixins/formValidation.js";
import validation from "@/mixin/validation";
import callApi from "../mixins/callApi";
import SelectItemTemplate from "@/components/parts/input/SelectItemTemplate";
import selectionLocalStorage from "@/mixin/selectionLocalStorage";

export default {
  components: {
    InputText,
    InputDate,
    Selectbox,
  },
  mixins: [validation, formValidation, callApi, selectionLocalStorage],
  data() {
    return {
      selectedGeneratorCollect : [null], //選択した運搬事業者
      selectedGeneratorDisposal: [null], //選択した処分事業者
      autoSelect: false,
      selectTemplate: SelectItemTemplate,
      // 詳細画面の表示フラグ
      isSearchDetail: false,
      searchParam: {
        subject: "", // 件名
        memberId: null, // 担当者
        collectCompanyId: [null], // 運搬事業者
        disposalCompanyId: [null], //処分事業者
        createDateFrom: "", // 登録日
        createDateTo: "", // 登録日
        nextUpdateDateFrom: "", // 次回更新日
        nextUpdateDateTo: "", // 次回更新日
        isSetFile: false, // 添付ファイル有無
      },
      isLoadingSearchGeneratorCollect: true,
      isLoadingSearchGeneratorDisposal: true,
    };
  },

  methods: {
    onChange(queryString, ref) {
      this.$nextTick(() => {
          this.$refs[ref].searchText = queryString;
      });
    },
    // 条件リセットボタンを押下
    resetSearchHandler() {
      //全ての事業者の選択肢を初期化する
      this.selectGeneratorCollectList = this.generatorCollectList
      this.selectGeneratorDisposalList = this.generatorDisposalList
      this.selectedGeneratorCollect = [null] //選択した運搬事業者
      this.selectedGeneratorDisposal = [null] //選択した処分事業者
      // 全ての値を初期化する
      this.searchParam = {
        subject: "", // 件名
        memberId: null, // 担当者
        collectCompanyId: [null], // 運搬事業者
        disposalCompanyId: [null], //処分事業者
        createDateFrom: "", // 登録日
        createDateTo: "", // 登録日
        nextUpdateDateFrom: "", // 次回更新日
        nextUpdateDateTo: "", // 次回更新日
        isSetFile: false, // 添付ファイル有無
      };
    },
    // 検索ボタンを押下
    searchHandler() {
      // 入力した結果を親コンポーネントに渡す
      this.$emit("search", this.searchParam);
    },
    // 簡易・詳細ボタンを押下
    initSearch(isAll = true) {
      // 詳細 ⇨ 簡易の順番で押された場合、詳細で表示される入力欄の値は初期化する
      this.searchParam = {
        subject: this.searchParam.subject,
        memberId: this.searchParam.memberId,
        collectCompanyId: this.searchParam.collectCompanyId,
        disposalCompanyId: this.searchParam.disposalCompanyId,
        createDateFrom: isAll ? "" : this.searchParam.createDateFrom,
        createDateTo: isAll ? "" : this.searchParam.createDateTo,
        nextUpdateDateFrom: isAll ? "" : this.searchParam.nextUpdateDateFrom,
        nextUpdateDateTo: isAll ? "" : this.searchParam.nextUpdateDateTo,
        isSetFile: isAll ? false : this.searchParam.isSetFile,
      };
    },
    // 運搬事業所の項目追加(+アイコン)押下
    addCollect() {
      //追加した項目の選択肢の初期化
      this.selectGeneratorCollectList = this.generatorCollectList
      this.selectedGeneratorCollect.push(null);
      this.searchParam.collectCompanyId.push(null);
    },
    // 運搬事業所の項目削除(✖️アイコン)押下
    deleteCollectList(index) {
      this.selectedGeneratorCollect.splice(index,1);
      this.searchParam.collectCompanyId.splice(index, 1);
    },
    // 処分事業所の項目追加(+アイコン)押下
    addDisposal() {
      //追加した項目の選択肢の初期化
      this.selectGeneratorDisposalList = this.generatorDisposalList
      this.selectedGeneratorDisposal.push(null);
      this.searchParam.disposalCompanyId.push(null);
    },
    // 処分事業所の項目削除(✖️アイコン)押下
    deleteDisposalList(index) {
      this.selectedGeneratorDisposal.splice(index, 1);
      this.searchParam.disposalCompanyId.splice(index, 1);
    },
    updateGeneratorCollectList (text) {
      if (!text || text.length === 0) {
        //全ての運搬事業者の選択肢を表示する
        this.selectGeneratorCollectList = this.generatorCollectList
        return
      }
      this.selectGeneratorCollectList = this.generatorCollectList.filter((item) => {
        return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
      })
    },
    updateGeneratorDisposalList (text) {
      if (!text || text.length === 0) {
        //全ての処分事業者の選択肢を表示する
        this.selectGeneratorDisposalList = this.generatorDisposalList
        return
      }
      this.selectGeneratorDisposalList = this.generatorDisposalList.filter((item) => {
        return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
      })
    },
    getLabel (item) {
      if (item) {
        return item.name
      }
      return ''
    },
  },
  watch: {
    selectedGeneratorCollect: function(newVal) {
      if (!this.isLoadingSearchGeneratorCollect) {
        newVal.forEach( (e, i) => {
          this.searchParam.collectCompanyId[i] = e ? e.id : null
        })
      }
    },
    selectedGeneratorDisposal: function(newVal) {
      if (!this.isLoadingSearchGeneratorDisposal) {
        newVal.forEach( (e, i) => {
          this.searchParam.disposalCompanyId[i] = e ? e.id : null
        })
      }
    }
  },

  created() {
    this.searchParam = this.$store.getters["searchParamShareinfo/get"];
    this.isSearchDetail = this.$store.getters["searchParamShareinfo/isSearchDetail"];
    // 担当者情報取得API
    this.getShareInfoMemberListApi();
    // 処分事業者情報取得API
    this.getGeneratorDisposalApi();
    // 運搬事業者情報取得API
    this.getGeneratorCollectApi();
  },
};
</script>

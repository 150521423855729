<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="共有情報一覧" />
    <AppSideMenu />
    <main class="l-main">
      <!-- 検索フォーム -->
      <SearchForm @search="getShareInfoSearchApi" />

      <!-- 検索結果一覧表示フィールド START -->
      <div class="l-container">
        <ErrorMessage :errMsgs="errMsgs" />

        <!-- 項目の列名表示 -->
        <div class="c-infoList__wrap">
          <div class="c-infoList scroll l-box">
            <div class="c-infoList__row head">
              <div class="c-infoList__cell md">件名</div>
              <div class="c-infoList__cell sm">担当者</div>
              <div class="c-infoList__cell sm">
                <a @click="clickSortChangeHandler">
                  <span
                    class="c-infoList__cell__sort"
                    :class="{
                      asc: sortParams.type.toLowerCase() === 'asc',
                      desc: sortParams.type.toLowerCase() === 'desc',
                    }"
                  >
                    登録日
                  </span>
                </a>
              </div>
              <div class="c-infoList__cell sm">次回更新予定日</div>
              <div class="c-infoList__cell sm">添付ファイル</div>
              <div class="c-infoList__cell xxsm"></div>
              <div class="c-infoList__cell xxsm"></div>
            </div>

            <!-- 検索結果あり START -->
            <template v-if="shareInfoList.length">
              <div
                v-for="(shareInfo, shareInfoIndex) of shareInfoList"
                :key="'shareinfo-' + shareInfoIndex"
                class="c-infoList__row list"
              >
                <!-- 件名データ表示 -->
                <div class="c-infoList__cell md c-tooltip">
                  <router-link :to="gotoDetail(shareInfo)">
                    {{
                      shareInfo.title.length > 30
                        ? shareInfo.title.slice(0, 30) + "..."
                        : shareInfo.title
                    }}
                  </router-link>
                  <div
                    v-if="shareInfo.title.length > 30"
                    class="c-tooltip__balloon lh"
                  >
                    <span>{{ shareInfo.title.name }}</span>
                  </div>
                </div>

                <!-- 担当者データ表示 -->
                <div class="c-infoList__cell sm c-tooltip">
                  {{
                    shareInfo.registMemberInfo.name.length > 30
                      ? shareInfo.registMemberInfo.name.slice(0, 30) + "..."
                      : shareInfo.registMemberInfo.name
                  }}
                  <div
                    v-if="shareInfo.registMemberInfo.name.length > 30"
                    class="c-tooltip__balloon lh"
                  >
                    <span>{{ shareInfo.registMemberInfo.name }}</span>
                  </div>
                </div>

                <!-- 登録日データ表示 -->
                <div class="c-infoList__cell sm">
                  {{ shareInfo.createDate | standardDateFormatJpYMDdd }}
                </div>

                <!-- 登録日データ表示 -->
                <div class="c-infoList__cell sm">
                  {{ shareInfo.nextUpdateDate | standardDateFormatJpYMDdd }}
                </div>

                <!-- 添付ファイル有無データ表示 -->
                <div class="c-infoList__cell sm">
                  <div v-if="shareInfo.isSetFile" class="attachment-icon"/>
                </div>

                <!-- 編集ボタン表示 -->
                <div class="c-infoList__cell xxsm sticky">
                  <div>
                    <router-link :to="gotoEdit(shareInfo)">
                      <div class="c-btn-icon edit secondary small"></div>
                    </router-link>
                  </div>
                </div>

                <!-- 削除ボタン表示 -->
                <div class="c-infoList__cell xxsm">
                  <div>
                    <a
                      class="c-btn-icon delete secondary sm"
                      @click="showDeleteModal(shareInfo)"
                    />
                  </div>
                </div>
              </div>
            </template>
            <!-- 検索結果ありフィールド END -->

            <!-- 検索結果なしフィールド START -->
            <template v-else>
              <div class="noSearchInfo">
                <div class="c-infoList__row list">
                  <div class="c-infoList__cell">
                    検索条件に一致する共有情報はありません。
                  </div>
                </div>
              </div>
            </template>
            <!-- 検索結果なしフィールド END -->
          </div>
        </div>

        <!-- ページャー表示フィールド START -->
        <Pagination
          v-if="shareInfoList.length"
          :params="paginationParams"
          :page.sync="paginationParams.page"
          @callback="getShareInfoSearchApi(searchedParam, true)"
        />
        <!-- ページャー表示フィールド END -->

        <!--削除確認モーダル-->
        <div class="c-modal" v-if="isDisplayDeleteModal">
          <div class="c-modal__overlay"></div>
          <div class="c-modal__content">
            <div class="c-modal__body">
              データを削除します。<br />
              よろしいですか？
            </div>
            <div class="c-modal__foot">
              <div
                class="c-btn secondary small"
                @click="isDisplayDeleteModal = false"
              >
                戻る
              </div>
              <div class="c-btn primary small" @click="deleteHandler()">OK</div>
            </div>
          </div>
        </div>
        <!--削除確認モーダルここまで-->
      </div>
      <!-- 検索結果一覧表示フィールド END -->
    </main>
    <AppFooter />
  </div>
</template>

<script>
import SearchForm from "./components/SearchForm.vue";
import Pagination from "@/components/parts/Pagination";
import validation from "@/mixin/validation";
import callApi from "./mixins/callApi.js";
import formValidation from "./mixins/formValidation.js";

export default {
  name: "shareinfo-list",
  components: {
    SearchForm,
    Pagination,
  },
  mixins: [validation, formValidation, callApi],
  data() {
    return {
      // 削除確認モーダル表示フラグ
      isDisplayDeleteModal: false,
      // 検索内容別で持つ。ページ移動でもAPI叩くため
      searchedParam: {},
      // ページネーション用
      paginationParams: {
        page: 1,
        pageSize: 10,
        total: null,
      },
      // 登録日昇順降順フラグ
      sortParams: {
        column: "create_date",
        type: "asc",
      },
      deleteId: null,
      errMsgs: [],
    };
  },
  methods: {
    // 件名押下で詳細画面へ遷移
    gotoDetail(shareinfo) {
      return "/generate/share/info/" + shareinfo.shareInfoId;
    },
    // 鉛筆アイコン押下で編集画面へ遷移
    gotoEdit(shareinfo) {
      return "/generate/share/edit/" + shareinfo.shareInfoId;
    },
    // 登録日ソート変更
    clickSortChangeHandler() {
      this.sortParams.type =
        this.sortParams.type.toLowerCase() === "asc" ? "desc" : "asc";
      this.getShareInfoSearchApi(this.searchedParam, false);
    },
    // ✖️アイコン押下で削除確認ダイアログを表示
    showDeleteModal(shareinfo) {
      this.deleteId = shareinfo.shareInfoId;
      this.isDisplayDeleteModal = true;
    },
    // 削除確認ダイアログの「OK」押下で対象データを削除
    deleteHandler() {
      // 削除APIを呼ぶ
      this.deleteShareInfoApi(this.deleteId, false);
      this.isDisplayDeleteModal = false;
    },
  },
  created() {
    this.getShareInfoSearchApi(this.$store.getters["searchParamShareinfo/get"], false);
  },
};
</script>
<style lang="scss" scoped>
.attachment-icon {
  width: 7rem;
  height: 2rem;
  display: block;
  background: {
    image: url("/v_resources/assets/img/icon/attachment.svg");
    repeat: no-repeat;
    position: center;
  }
}
.noSearchInfo {
  width: 100%;
}
</style>
